.maha-mediafield-photos {
  padding: 0.4rem;
  display: flex;
  flex-wrap: wrap;
}
.maha-mediafield-photo {
  max-width: 100px;
  background-color: $white;
  border: 1px solid fadeout($black, 90);
  position: relative;
}
.maha-mediafield-remove {
  border: 1px solid fadeout($black, 90);
  @include flex-justify-middle-center;
  background-color: $white;
  border-radius: 50%;;
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  padding: 0.2rem;
  line-height: 1rem;
  font-size: 0.8rem;
  text-shadow: 0 0 2px fadeout($black, 60);
  cursor: pointer;
  color: fadeout($black, 60);
  z-index: 1;
  @include hover {
    color: fadeout($black, 40);
  };
}

.maha-mediafield-preview {
  display: flex;
  flex: 1;
}
.maha-mediafield-preview-image {
  flex: 0 0 60px;
  position: relative;
  display: inline-block;
  padding: 0.4rem 0 0.4rem 0.8rem;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.maha-mediafield-preview-details {
  flex: 1;
}
.maha-mediafield-photo-preview {
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  height: 0;
  .maha-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.maha-mediafield-photo-label {
  padding: 0.8rem;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: fadeout($black, 60);
  }
}
.maha-mediafield-token {
  display: flex;
  flex: 1;
}
.maha-mediafield-token-photo {
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.maha-mediafield-token-caption {
  align-items: center;
  display: flex;
  padding: 0.8rem;
  span {
    text-transform: uppercase;
    color: fadeout($black, 80);
  }
}