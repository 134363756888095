.maha-phone-number {
  display: inline-flex;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  &.maha-button .maha-phone-number-label {
    color: $blue;
  }
  @include hover {
    &.maha-button .maha-phone-number-label {
      color: darken($blue, 20);
    }
  }
}
.maha-phone-number-flag {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0 0.4rem;
}
.maha-phone-number-label {
  padding: 0 0.4rem;
  flex: 1;
  line-height: 1.5rem;
}
.maha-phone-number-type {
  padding: 0 0.4rem 0 0;
  color: fadeout($black, 60);
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.5rem;
}
.maha-details-item-content-value {
  .maha-phone-number {
    margin: 0.2rem 0;
  }
}
