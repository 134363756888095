.maha-entity-chooser-instructions {
  border-bottom: 1px solid fadeout($black, 90);
  line-height: 1.8rem;
  padding: 0.8rem;
}
.maha-entity-chooser {
  display: flex;
  flex: 1;
}
.maha-entity-chooser-list {
  border-right: 1px solid fadeout($black, 90);
  position: relative;
  flex: 0 0 400px;
  display: flex;
}
.maha-entity-chooser-preview {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-entity-chooser-preview-body {
  position: relative;
  display: flex;
  flex: 1;
  iframe {
    border: 0;
    flex: 1;
  }
}
.maha-entity-chooser-preview-footer {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}

.maha-entity-chooser-result {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  cursor: pointer;
  &.selected {
    background-color: fadeout($blue, 90);
  }
}
.maha-entity-chooser-result-toggle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .fa-circle-o,
  .fa-square-o {
      color: fadeout($black, 80);
  }
  .fa-check-circle,
  .fa-check-square {
      color: $blue;
  }
}
.maha-entity-chooser-result-details {
  display: flex;
  flex: 1;
}